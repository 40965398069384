<template>
    <page-title
        title="スケジュール一覧"
        icon="bi-calendar3"
    >
    <button-exec
        text="更新"
        icon="bi-arrow-repeat"
        @click="fetchCalendars"
        :disabled="!isFetchingCalendars"
    ></button-exec>
    </page-title>

    <section class="section">
        <div class="row g-2 justify-content-around mb-2">
            <alert-box
                title="不足"
                :count="danger_shortage_alerts.length"
                :loading="danger_shortage_alert_loading"
                alert_color="alert-shortage-danger"
                @click="openDangerShortageAlertModal"
            ></alert-box>
            <alert-box
                title="不足気味"
                :count="warning_shortage_alerts.length"
                :loading="warning_shortage_alert_loading"
                alert_color="alert-shortage-warning"
                @click="openWarningShortageAlertModal"
            ></alert-box>
            <alert-box
                title="Wブッキング"
                :count="double_booking_alert_counts.length"
                :loading="double_booking_alert_loading"
                alert_color="alert-double-bookings"
                @click="openDoubleBookingAlertModal"
            ></alert-box>
            <alert-box
                title="備品不足"
                :count="equipment_alert_counts.length"
                :loading="equipment_alert_loading"
                alert_color="alert-equipments"
                @click="openEquipmentAlertModal"
            ></alert-box>
            <alert-box
                title="未配置"
                :count="unassigned_alerts.length"
                :loading="unassigned_alert_loading"
                alert_color="alert-unassigned"
                @click="openUnassignedAlertModal"
            ></alert-box>

            <alert-box
                title="たまご未"
                :count="alert_undecided_schedules.length"
                :loading="undecided_schedules_alert_loading"
                alert_color="alert-undecided"
                @click="$router.push({
                    name: 'AlertUndecidedSchedule',
                    params: {
                        photographer_id: condition.photographer_id
                    }
                })"
            ></alert-box>
            <alert-box
                title="日程確認未"
                :count="first_confirm_alerts.length"
                :loading="first_confirm_alert_loading"
                alert_color="alert-confirm-first"
                @click="openFirstConfirmAlertModal"
            ></alert-box>
            <alert-box
                title="最終確認未"
                :count="last_confirm_alerts.length"
                :loading="last_confirm_alert_loading"
                alert_color="alert-confirm-last"
                @click="openLastConfirmAlertModal"
            ></alert-box>
            <alert-box
                title="予備日未定"
                :count="spare_schedule_alerts.length"
                :loading="spare_schedule_alert_loading"
                alert_color="alert-spare-schedule"
                @click="openSpareScheduleAlertModal"
            ></alert-box>
            <alert-box
                title="カメラマン未定"
                :count="unselected_alerts.length"
                :loading="unselected_alert_loading"
                alert_color="alert-unselected"
                @click="openUnselectedAlertModal()"
            ></alert-box>
            <alert-box
                title="未連絡"
                :count="contact_alerts.length"
                :loading="contact_alert_loading"
                alert_color="alert-contact"
                @click="openContactAlertModal()"
            ></alert-box>
            <alert-box
                title="未完了"
                :count="status_alerts.length"
                :loading="status_alert_loading"
                alert_color="alert-status"
                @click="openStatusAlertModal()"
            ></alert-box>
        </div>
    </section>

    <section class="section">
        <div class="row g-3 align-items-center">
            <div class="col-md-4 px-1">
                <form-select-search
                    v-model="condition.schedule_type_key"
                    :options="schedule_types"
                    placeholder="-- 全種類 --"
                ></form-select-search>
            </div>
            <div class="col-md-4 px-1">
                <form-select-search
                    v-model="condition.studio_id"
                    :options="options_studio"
                    placeholder="-- 全店舗 --"
                ></form-select-search>
            </div>
            <div class="col-md-4 px-1">
                <form-select-search
                    v-model="condition.school_id"
                    :options="options_school"
                    placeholder="-- 全学校 --"
                ></form-select-search>
            </div>
            <div class="d-flex col-md-7 px-1">
                <div class="input-group">
                    <form-select
                        v-model="condition.search_type"
                        :options="search_types"
                        style="width:40%"
                    ></form-select>
                    <form-select-search
                        v-model="condition.photographer_id"
                        :options="condition.search_type === SearchType.EMPLOYEE ? options_employee : options_search_photographer"
                        style="width:60%"
                        placeholder="-- 全員 --"
                    ></form-select-search>
                </div>
                <div>
                    <button-exec
                        icon="bi-person-circle"
                        text=""
                        color="btn-link"
                        class="ms-1"
                        @click="setLoginUserIdInCondition"
                        title="自分を選択"
                    ></button-exec>
                </div>
            </div>
            <div class="col-md-3 px-1">
                <form-input
                    v-model="condition.schedule_name"
                    placeholder="自由入力"
                ></form-input>
            </div>
            <div class="col-md-2 text-center px-0">
                <button-search
                    @click="fetchCalendars()"
                    :disabled="calendar_loading"
                ></button-search>
            </div>
        </div>
    </section>

    <section class="section">
        <div class="d-flex justify-content-end mb-3">
            <div class="d-flex justify-content-around col-md-6">
                <div class="col-md text-end">
                    <button-search
                        icon="bi-skip-backward-fill"
                        text=""
                        size="btn-sm"
                        color="btn-outline-primary"
                        :disabled="calendar_loading"
                        @click="subMonth"
                    ></button-search>
                </div>
                <div class="col-md-10 text-center align-self-center mx-2">
                    <select
                        v-model="condition.date"
                        class="form-select form-select-sm"
                        @change="fetchCalendars()"
                    >
                        <template v-for="date in calendar_dates" :key="date">
                            <option :value="date.key">{{ date.label }}</option>
                        </template>
                    </select>
                </div>
                <div class="col-md">
                    <button-search
                        icon="bi-skip-forward-fill"
                        text=""
                        size="btn-sm"
                        color="btn-outline-primary"
                        :disabled="calendar_loading"
                        @click="addMonth"
                    ></button-search>
                </div>
                <div class="col-md">
                    <button-search
                        icon=""
                        text="今月"
                        size="btn-sm"
                        color="btn-outline-primary"
                        :disabled="calendar_loading"
                        @click="todayMonth"
                    ></button-search>
                </div>
            </div>
        </div>

        <inline-loader v-if="calendar_loading"></inline-loader>
        <template v-else>
            <template v-for="week, week_index in calendars" :key="week">
                <div class="d-flex" style="min-height:300px">
                    <template v-for="calendar, calendar_index in week" :key="calendar">
                        <div class="col border">
                            <!-- カレンダー曜日 -->
                            <div class="col bg-primary text-center border">
                                <span class="text-white">{{ calendar.day_display }}</span>
                            </div>
                            <div class="border-bottom" :class="displayHeaderColor(calendar)">
                                <div
                                    class="text-center fs-5 mx-2 position-relative"
                                    :class="generateHeaderDayColor(calendar, calendar_index)"
                                >
                                    <div
                                        v-if="isVisibleDoubleBookingAlert(calendar)"
                                        class="text-center alert-double-bookings position-absolute top-50 translate-middle-y rounded-circle border border-secondary"
                                    >
                                        <h1 class="is-visible-alert d-flex justify-content-center align-items-center"><i class="bi bi-exclamation-lg"></i></h1>
                                    </div>
                                    <span
                                        class="today-color"
                                        :class="generateHeaderTodayColor(calendar, calendar_index)"
                                    >
                                        {{ calendar.header_day }}
                                    </span>
                                    <!-- 備品不足アイコン表示 -->
                                    <div
                                        v-if="isVisibleEquipmentAlert(calendar)"
                                        class="text-center alert-equipments position-absolute top-50 translate-middle-y rounded-circle border border-secondary"
                                        :class="{'display-position-equipment-alert' : isVisibleDoubleBookingAlert(calendar)}"
                                    >
                                        <h1 class="is-visible-alert d-flex justify-content-center align-items-center"><i class="bi bi-exclamation-lg"></i></h1>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between align-items-center px-1 pb-1 mt-1">
                                    <div
                                        class="text-center px-1 pointer shadow-sm border rounded"
                                        @click="openAbsenceModal(calendar.absences, calendar.schedules, calendar.date)"
                                    >
                                        <i class="bi bi-person-dash"></i> <small>{{ calendar.absences.length }}</small>
                                    </div>
                                    <div
                                        class="text-center px-1 pointer shadow-sm border rounded"
                                        @click="openInvitationModal(calendar.invitations, calendar.schedules, calendar.date)"
                                    >
                                        <i class="bi bi-people-fill"></i> <small>{{ countInvitation(calendar.invitations) }}</small>
                                    </div>
                                    <div class="text-center">
                                        <i class="bi bi-camera-fill"></i> <small>{{ calendar.photographer_count }}</small>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <template v-for="schedule, schedule_index in calendar.schedules" :key="schedule">
                                    <div
                                        v-if="(schedule_index + 1) <= displayableNumber(week)"
                                        class="col-md m-1 border border-1 rounded border-dark px-1 pointer schedule-status"
                                        :class="displayScheduleColor(schedule)"
                                        style="font-size:.7rem;"
                                        @click="
                                            openUpdateScheduleModal(calendar.schedules[schedule_index]);
                                            setScheduleIndexes(week_index, calendar_index, schedule_index);
                                        "
                                    >
                                        <div class="schedule-status-icon"><i class="bi" :class="schedule.schedule_status_icon"></i></div>
                                        <div class="schedule-name" style="vertical-align: middle">
                                            <span style="font-size:.9rem;"><i class="bi me-1" :class="schedule.schedule_icon"></i></span>
                                            {{ calendar.schedules[schedule_index].schedule_name_display }}
                                        </div>
                                        <div class="text-end">
                                            {{ displayScheduleTime(calendar.schedules[schedule_index]) }}
                                        </div>
                                        <div class="text-end">
                                            <!-- カメラマン名の表示 -->
                                            <SchedulePhotographerShortenedName
                                                :schedule="calendar.schedules[schedule_index]"
                                            ></SchedulePhotographerShortenedName>
                                            {{ displayCarShortenedName(calendar.schedules[schedule_index].cars) }}
                                        </div>
                                    </div>
                                </template>
                                <!-- <div class="text-danger text-center" style="font-size:.8rem;">
                                    <i class="bi bi-exclamation-triangle"></i> 社用車不足(3/2)
                                </div> -->
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <button-exec
                                            text="追加"
                                            icon="bi-calendar2-plus"
                                            color="btn-link"
                                            size="btn-sm"
                                            style="font-size:.8rem;"
                                            @click="openCreateScheduleModal(calendar.date_for_input);"
                                        ></button-exec>
                                    </div>
                                    <div v-if="calendar.schedules.length">
                                        <button-exec
                                            :text="'他' + (displayAnotherSchedulesNumber(calendar, week)) + '件...'"
                                            icon=""
                                            color="btn-link"
                                            size="btn-sm"
                                            style="font-size:.8rem;"
                                            @click="openOffcanvasSchedules(calendar); setScheduleIndexes(week_index, calendar_index)"
                                        ></button-exec>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
            <div class="d-flex justify-content-end mt-3">
                <div class="d-flex justify-content-around col-md-6">
                    <div class="col-md text-end">
                        <button-search
                            icon="bi-skip-backward-fill"
                            text=""
                            size="btn-sm"
                            color="btn-outline-primary"
                            :disabled="calendar_loading"
                            @click="subMonth"
                        ></button-search>
                    </div>
                    <div class="col-md-10 text-center align-self-center mx-2">
                        <select
                            v-model="condition.date"
                            class="form-select form-select-sm"
                            @change="fetchCalendars()"
                        >
                            <template v-for="date in calendar_dates" :key="date">
                                <option :value="date.key">{{ date.label }}</option>
                            </template>
                        </select>
                    </div>
                    <div class="col-md">
                        <button-search
                            icon="bi-skip-forward-fill"
                            text=""
                            size="btn-sm"
                            color="btn-outline-primary"
                            :disabled="calendar_loading"
                            @click="addMonth"
                        ></button-search>
                    </div>
                    <div class="col-md">
                        <button-search
                            icon=""
                            text="今月"
                            size="btn-sm"
                            color="btn-outline-primary"
                            :disabled="calendar_loading"
                            @click="todayMonth"
                        ></button-search>
                    </div>
                </div>
            </div>
        </template>
    </section>

    <section class="section" v-if="!loading && !calendar_loading">
        <div class="row g-3 align-items-center">
            <div class="col-md-6">
                <form-select-search
                    v-model="condition.school_id"
                    :options="options_school"
                    placeholder="-- 全学校 --"
                ></form-select-search>
            </div>
            <div class="d-flex col-md-9">
                <div class="input-group">
                    <form-select
                        v-model="condition.search_type"
                        :options="search_types"
                        style="width:40%"
                    ></form-select>
                    <form-select-search
                        v-model="condition.photographer_id"
                        :options="condition.search_type === SearchType.EMPLOYEE ? options_employee : options_search_photographer"
                        style="width:60%"
                        placeholder="-- 全員 --"
                    ></form-select-search>
                </div>
                <div>
                    <button-exec
                        icon="bi-person-circle"
                        text=""
                        color="btn-link"
                        class="ms-1"
                        @click="setLoginUserIdInCondition"
                        title="自分を選択"
                    ></button-exec>
                </div>
            </div>
            <div class="col-md-7">
                <form-input
                    v-model="condition.schedule_name"
                    placeholder="自由入力"
                ></form-input>
            </div>
            <div class="col-md-2 text-center">
                <button-search
                    @click="fetchCalendars()"
                    :disabled="calendar_loading"
                ></button-search>
            </div>
        </div>
    </section>

    <!-- 詳細バー -->
    <ScheduleOffcanvas
        v-if="schedule_offcanvas_show"
        placement="offcanvas-end"
        title="スケジュール詳細一覧"
        :date="schedule_offcanvas_date"
        @close="schedule_offcanvas_show = false;"
        @createNewSchedule="openCreateScheduleModal(schedule_offcanvas_date)"
    >
        <inline-loader v-if="offcanvas_loading"></inline-loader>
        <template v-else>
            <div class="d-flex justify-content-between mt-3">
                <div>
                    <button-exec
                        text=""
                        icon="bi-skip-backward-fill"
                        @click="subDay()"
                        size="btn-sm"
                    ></button-exec>
                </div>
                <div>
                    <button-exec
                        text=""
                        icon="bi-skip-forward-fill"
                        @click="addDay()"
                        size="btn-sm"
                    ></button-exec>
                </div>
            </div>
            <template v-for="schedule, index in offcanvas_schedules" :key="schedule">
                <div
                    class="m-1 border border-1 rounded border-dark px-1 pointer schedule-status"
                    :class="displayScheduleColor(schedule)"
                    style="font-size:.9rem;"
                    @click="openUpdateScheduleModal(schedule); schedule_index = index"
                >
                    <div class="schedule-status-icon"><i class="bi" :class="schedule.schedule_status_icon"></i></div>
                    <div style="vertical-align: middle">
                        <span style="font-size:.9rem;"><i class="bi me-1" :class="schedule.schedule_icon"></i></span>
                        {{ schedule.schedule_name_display }}
                    </div>
                    <div class="text-end">
                        {{ displayScheduleTime(offcanvas_schedules[index]) }}
                    </div>
                    <div class="text-end">
                        <!-- カメラマン名の表示 -->
                        <SchedulePhotographerShortenedName
                            :schedule="offcanvas_schedules[index]"
                        ></SchedulePhotographerShortenedName>
                        {{ displayCarShortenedName(schedule.cars) }}
                    </div>
                </div>
            </template>
            <div class="d-flex justify-content-between mb-3" v-if="(offcanvas_schedules.length >= 10)">
                <div>
                    <button-exec
                        text=""
                        icon="bi-skip-backward-fill"
                        @click="subDay()"
                        size="btn-sm"
                    ></button-exec>
                </div>
                <div>
                    <button-exec
                        text=""
                        icon="bi-skip-forward-fill"
                        @click="addDay()"
                        size="btn-sm"
                    ></button-exec>
                </div>
            </div>
        </template>
    </ScheduleOffcanvas>

    <!-- 休暇管理モーダル -->
    <AbsenceModal
        v-if="absence_modal_show"
        v-model="absence_modal_absences"
        :employees="employees"
        :schedules="absence_modal_schedules"
        :date="absence_modal_date"
        @close="absence_modal_show = false; setAutoFetchCalendars();"
        @update="fetchCalendars();"
    ></AbsenceModal>

    <!-- 応援カメラマン依頼モーダル -->
    <InvitationModal
        v-if="invitation_modal_show"
        v-model="invitation_modal_invitations"
        :invitation_partners="invitation_partners"
        :other_partners="other_partners"
        :options_other_partner="options_other_partner"
        :schedules="invitation_modal_schedules"
        :date="invitation_modal_date"
        @close="invitation_modal_show = false; setAutoFetchCalendars();"
        @update="fetchCalendars();"
    ></InvitationModal>

    <!-- 不足アラートモーダル -->
    <AlertShortageModal
        v-if="danger_shortage_alert_modal"
        :shortage_calendars="danger_shortage_alerts"
        :shortage_status="ShortageStatus.DANGER"
        @close="danger_shortage_alert_modal = false; setAutoFetchCalendars();"
        @setAlertDate="jumpToShortageAlertMonth"
    ></AlertShortageModal>

    <!-- 不足気味アラートモーダル -->
    <AlertShortageModal
        v-if="warning_shortage_alert_modal"
        :shortage_calendars="warning_shortage_alerts"
        :shortage_status="ShortageStatus.WARNING"
        @close="warning_shortage_alert_modal = false; setAutoFetchCalendars();"
        @setAlertDate="jumpToShortageAlertMonth"
    ></AlertShortageModal>

    <!-- ダブルブッキングアラートモーダル -->
    <AlertDoubleBookingModal
        v-if="double_booking_alert_modal"
        :double_booking_alerts="double_booking_alert_counts"
        @close="double_booking_alert_modal = false; setAutoFetchCalendars();"
        @setSchedule="openUpdateScheduleModal"
    ></AlertDoubleBookingModal>

    <!-- 備品不足アラートモーダル -->
    <AlertEquipmentModal
        v-if="equipment_alert_modal"
        :equipment_alerts="equipment_alert_counts"
        @close="equipment_alert_modal = false; setAutoFetchCalendars();"
        @setSchedule="openUpdateScheduleModal"
    ></AlertEquipmentModal>

    <!-- 未配置アラートモーダル -->
    <AlertUnassignedModal
        v-if="unassigned_alert_modal"
        :unassigned_invitations="unassigned_alerts"
        @close="unassigned_alert_modal = false; setAutoFetchCalendars();"
    ></AlertUnassignedModal>

    <!-- 日程確認未実施アラートモーダル -->
    <ScheduleAlertModal
        v-if="first_confirm_alert_modal"
        :alert_schedules="first_confirm_alerts"
        modal_title="日程確認未実施アラート"
        alert_message="予定日の60日前ですが、「日程確認日」が60日以上前です。日程を学校に確認してください"
        alert_color="alert-confirm-first"
        @close="first_confirm_alert_modal = false; setAutoFetchCalendars();"
        @setSchedule="openUpdateScheduleModal"
    ></ScheduleAlertModal>

    <!-- 日程最終確認未実施アラートモーダル -->
    <ScheduleAlertModal
        v-if="last_confirm_alert_modal"
        :alert_schedules="last_confirm_alerts"
        modal_title="日程最終確認未実施アラート"
        alert_message="予定日の14日前ですが、「日程確認日」が 14日以上前です。日程を学校に最終確認してください"
        alert_color="alert-confirm-last"
        @close="last_confirm_alert_modal = false; setAutoFetchCalendars();"
        @setSchedule="openUpdateScheduleModal"
    ></ScheduleAlertModal>

    <!-- 予備日未定アラートモーダル -->
    <ScheduleAlertModal
        v-if="spare_schedule_alert_modal"
        :alert_schedules="spare_schedule_alerts"
        modal_title="予備日未定アラート"
        alert_message="予定日30日前ですが、予備日が未定です。確認してください"
        alert_color="alert-spare-schedule"
        @close="spare_schedule_alert_modal = false; setAutoFetchCalendars();"
        @setSchedule="openUpdateScheduleModal"
    ></ScheduleAlertModal>

    <!-- カメラマン未選択アラートモーダル -->
    <PhotographerAlertModal
        v-if="unselected_alert_modal"
        :alert_schedules="unselected_alerts"
        modal_title="カメラマン未選択アラート"
        alert_message="予定日14日前ですが、カメラマンが未選択です。カメラマンを選択してください"
        alert_color="alert-unselected"
        @close="unselected_alert_modal = false; setAutoFetchCalendars();"
        @setSchedule="openUpdateScheduleModal"
    ></PhotographerAlertModal>

    <!-- カメラマン未連絡アラートモーダル -->
    <PhotographerAlertModal
        v-if="contact_alert_modal"
        :alert_schedules="contact_alerts"
        modal_title="カメラマン未連絡アラート"
        alert_message="予定日7日前ですが、カメラマンに連絡済になっていません。カメラマンに連絡してください"
        alert_color="alert-contact"
        @close="contact_alert_modal = false; setAutoFetchCalendars();"
        @setSchedule="openUpdateScheduleModal"
    ></PhotographerAlertModal>

    <!-- ステータス未更新アラートモーダル -->
    <PhotographerAlertModal
        v-if="status_alert_modal"
        :alert_schedules="status_alerts"
        modal_title="ステータス未更新アラート"
        alert_message="予定日を過ぎましたが、ステータスが更新されていません。スケジュールを確認してください"
        alert_color="alert-status"
        @close="status_alert_modal = false; setAutoFetchCalendars();"
        @setSchedule="openUpdateScheduleModal"
    ></PhotographerAlertModal>

    <!-- スケジュール詳細モーダル -->
    <ScheduleModal
        v-if="schedule_modal_show"
        v-model="target_schedule"
        :modal_title="schedule_modal_title"
        :equipments="equipments"
        :remaining_equipments="remaining_equipments"
        :employees="employees"
        :options_studio="options_studio"
        :options_school="options_school"
        :options_employee="options_employee"
        :options_employee_photographer="options_employee_photographer"
        @close="schedule_modal_show = false; setScheduleIndexes(); setAutoFetchCalendars();"
        @remove="deleteTargetSchedule(); fetchCalendars();"
        @create="schedule_modal_show = false; fetchCalendars(); fetchCalendar(target_schedule.schedule_date)"
        @update="fetchCalendars(); fetchCalendar(target_schedule.schedule_date)"
        @openParentScheduleModal="openParentScheduleModal"
    ></ScheduleModal>

    <!-- 本予定確認モーダル -->
    <ScheduleModal
        v-if="parent_schedule_modal_show"
        v-model="parent_schedule"
        :modal_title="parent_schedule_modal_title"
        :modal_title_notice="parent_schedule_modal_title_notice"
        :equipments="equipments"
        :cars="cars"
        :employees="employees"
        :options_school="options_school"
        :options_employee="options_employee"
        :options_employee_photographer="options_employee_photographer"
        :is_readonly="is_readonly"
        @close="parent_schedule_modal_show = false; is_readonly = false;"
    ></ScheduleModal>

</template>

<script>
import PageTitle from '@/components/PageTitle';
import AlertBox from '@/components/AlertBox';
import InlineLoader from '@/components/tools/InlineLoader';
import FormInput from '@/components/forms/FormInput';
import FormSelect from '@/components/forms/FormSelect';
import FormSelectSearch from '@/components/forms/FormSelectSearch';
import ButtonSearch from '@/components/buttons/ButtonSearch';
import ButtonExec from '@/components/buttons/ButtonExec';
import SchedulePhotographerShortenedName from '@/components/schedule/SchedulePhotographerShortenedName';
import ScheduleModal from '@/components/schedule/ScheduleModal';
import AbsenceModal from '@/components/schedule/AbsenceModal';
import InvitationModal from '@/components/schedule/InvitationModal';
import AlertShortageModal from '@/components/schedule/AlertShortageModal';
import AlertDoubleBookingModal from '@/components/schedule/AlertDoubleBookingModal';
import AlertEquipmentModal from '@/components/schedule/AlertEquipmentModal';
import AlertUnassignedModal from '@/components/schedule/AlertUnassignedModal';
import ScheduleAlertModal from '@/components/schedule/ScheduleAlertModal';
import PhotographerAlertModal from '@/components/schedule/PhotographerAlertModal';
import ScheduleOffcanvas from '@/components/schedule/ScheduleOffcanvas';
import Calendar from '@/models/entities/calendar';
import Schedule from '@/models/entities/schedule';
import Employee from '@/models/entities/employee';
import Partner from '@/models/entities/partner';
import Car from '@/models/entities/car';
import Equipment from '@/models/entities/equipment';
import Invitation from '@/models/entities/invitation';
import UndecidedSchedule from '@/models/entities/undecided-schedule';
import AlertDoubleBooking from '@/models/entities/alert-double-booking';
import AlertEquipment from '@/models/entities/alert-equipment';
import SearchType from '@/models/enums/schedule/search-type';
import InvitationStatus from '@/models/enums/schedule/invitation-status';
import ShortageStatus from '@/models/enums/schedule/shortage-status';
import IsHoliday from '@/models/enums/schedule/is-holiday';
import IsAlert from '@/models/enums/schedule/is-alert';
import HasSeveralDays from '@/models/enums/schedule/has-several-days';
import IsUnfixed from '@/models/enums/schedule/is-unfixed';
import UnfixedType from '@/models/enums/schedule/unfixed-type';
import IsPhotographer from '@/models/enums/employee/is-photographer';
import IgnoreAlert from '@/models/enums/schedule/ignore-alert';
import ScheduleType from '@/models/enums/schedule/schedule-type';

export default {
    name: 'Schedules',
    components: {
        PageTitle,
        AlertBox,
        InlineLoader,
        FormInput,
        FormSelect,
        FormSelectSearch,
        ButtonSearch,
        ButtonExec,
        ScheduleModal,
        AbsenceModal,
        InvitationModal,
        AlertShortageModal,
        AlertDoubleBookingModal,
        AlertEquipmentModal,
        AlertUnassignedModal,
        ScheduleAlertModal,
        PhotographerAlertModal,
        ScheduleOffcanvas,
        SchedulePhotographerShortenedName
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            loading: 0,
            calendar_loading: 0,
            offcanvas_loading: 0,
            danger_shortage_alert_loading: 0,
            warning_shortage_alert_loading: 0,
            double_booking_alert_loading: 0,
            equipment_alert_loading: 0,
            unassigned_alert_loading: 0,
            undecided_schedules_alert_loading: 0,
            first_confirm_alert_loading: 0,
            last_confirm_alert_loading: 0,
            spare_schedule_alert_loading: 0,
            unselected_alert_loading: 0,
            contact_alert_loading: 0,
            status_alert_loading: 0,


            calendars: [],
            offcanvas_schedules: [],
            schools: [],
            employees: [],
            employee_photographers: [],
            invitation_partners: [],
            other_partners: [],
            cars: [],
            equipments: [],
            shortened_names: [],

            condition: {
                schedule_type_key: null,
                studio_id: null,
                school_id: null,
                photographer_id: null,
                schedule_name: null,
                search_type: SearchType.EMPLOYEE,
                date: this.getThisMonth(),
            },

            // options
            search_types: SearchType.options(),
            schedule_types: ScheduleType.userOptions(),
            options_studio: [],
            options_school: [],
            options_employee: [],
            options_search_photographer: [],
            options_other_partner: [],
            options_employee_photographer: [],

            // Enums
            SearchType: SearchType,
            InvitationStatus: InvitationStatus,
            ShortageStatus: ShortageStatus,
            IsHoliday: IsHoliday,
            IsAlert: IsAlert,
            IsUnfixed: IsUnfixed,
            UnfixedType: UnfixedType,
            HasSeveralDays: HasSeveralDays,
            IsPhotographer: IsPhotographer,
            IgnoreAlert: IgnoreAlert,

            // スケジュールオフキャンバス
            schedule_offcanvas_show: false,
            schedule_offcanvas_date: null,

            // スケジュール詳細モーダル
            schedule_modal_show: false,
            schedule_modal_title: null,
            target_schedule: new Schedule(),
            week_index: null,
            calendar_index: null,
            schedule_index: null,
            remaining_equipments: [],

            // 本予定
            parent_schedule_modal_show: false,
            parent_schedule_modal_title: null,
            parent_schedule: new Schedule(),
            is_readonly: false,

            // 休暇モーダル
            absence_modal_show: false,
            absence_modal_absences: [],
            absence_modal_schedules: [],
            absence_modal_date: null,

            // 応援カメラマン依頼モーダル
            invitation_modal_show: false,
            invitation_modal_invitations: [],
            invitation_modal_schedules: [],
            invitation_modal_date: null,

            // カメラマン不足モーダル
            danger_shortage_alert_modal: false,
            warning_shortage_alert_modal: false,
            danger_shortage_alerts: [],
            warning_shortage_alerts: [],

            // ダブルブッキングモーダル
            double_booking_alert_modal: false,
            double_booking_alerts: [],
            double_booking_alert_counts: [],

            // 備品不足モーダル
            equipment_alert_modal: false,
            equipment_alerts: [],
            equipment_alert_counts: [],

            // 未配置アラート
            unassigned_alert_modal: false,
            unassigned_alerts: [],

            // 予定のたまごアラート
            alert_undecided_schedules: [],

            // 日程確認未実施アラート
            first_confirm_alerts: [],
            first_confirm_alert_modal: false,

            // 日程最終確認未実施アラート
            last_confirm_alerts: [],
            last_confirm_alert_modal: false,

            // 予備日未定アラート
            spare_schedule_alerts: [],
            spare_schedule_alert_modal: null,

            // カメラマン未選択アラート
            unselected_alerts: [],
            unselected_alert_modal: null,

            // カメラマン未連絡アラート
            contact_alerts: [],
            contact_alert_modal: null,

            // ステータス未更新アラート
            status_alerts: [],
            status_alert_modal: null,

            // 5分ごとのカレンダー・アラート自動更新
            auto_fetch_calendars: null,
        }
    },
    mounted() {
        this.fetchAllSchools();
        this.fetchAllEmployees();
        this.fetchAllPartners();
        this.fetchCarList();
        this.FetchEquipmentList();
        this.fetchCalendars();
        this.fetchInvitationPartners();
        this.fetchOtherPartners();
        this.setAutoFetchCalendars();
        this.fetchAllStudiosForOptions();
    },
    beforeUnmount () {
        //他のページに遷移したら自動API取得を停止
        this.clearAutoFetchCalendars();
    },
    computed: {
        calendar_dates() {
            // 一年前
            let start = new Date(this.condition.date);
            start.setDate(1);
            start.setFullYear(start.getFullYear() - 1);
            // 一年後
            let end = new Date(this.condition.date);
            end.setDate(1);
            end.setFullYear(end.getFullYear() + 1);

            // 間の日付を取得(１ヶ月間隔)
            let dates = [];
            for (let date = start; date <= end; date.setMonth(date.getMonth()+1)) {
                let target_date = new Date(date.valueOf());

                let calendar_date = {
                    key: `${target_date.getFullYear()}-${("00" + (target_date.getMonth()+1)).slice(-2)}-${("00" + target_date.getDate()).slice(-2)}`,
                    label: `${target_date.getFullYear()}年${("00" + (target_date.getMonth()+1)).slice(-2)}月`
                }
                dates.push(calendar_date);
            }

            return dates;
        },
        isFetchingCalendars() {
            if (
                this.calendar_loading ||
                this.danger_shortage_alert_loading ||
                this.warning_shortage_alert_loading ||
                this.double_booking_alert_loading ||
                this.equipment_alert_loading ||
                this.undecided_schedules_alert_loading ||
                this.first_confirm_alert_loading ||
                this.last_confirm_alert_loading ||
                this.spare_schedule_alert_loading ||
                this.unselected_alert_loading ||
                this.contact_alert_loading ||
                this.status_alert_loading
            ) {
                return false;
            }

            return true;
        },
    },
    watch: {
        'condition.search_type'() {
            this.condition.photographer_id = null;
        },
    },
    methods: {
        // 検索条件に自分をセットする
        setLoginUserIdInCondition() {
            this.condition.photographer_id = this.$store.state.auth.photographer_id;
        },
        /**
         * カレンダー系
         */
        // 今日の日付を取得
        getToday() {
            let dt = new Date();
            let y = dt.getFullYear();
            let m = ("00" + (dt.getMonth()+1)).slice(-2);
            let d = ("00" + dt.getDate()).slice(-2);
            let result = y + "-" + m + "-" + d;

            return result;
        },
        // 今月1日を取得
        getThisMonth() {
            let dt = new Date();
            dt.setDate(1);
            let y = dt.getFullYear();
            let m = ("00" + (dt.getMonth()+1)).slice(-2);
            let d = ("00" + dt.getDate()).slice(-2);
            let result = y + "-" + m + "-" + d;

            return result;
        },
        // 今日かどうか
        isToday(calendar) {
            let calendar_date = new Date(calendar.date);
            let today = new Date();

            if (
                calendar_date.getFullYear() === today.getFullYear() &&
                calendar_date.getMonth() === today.getMonth() &&
                calendar_date.getDate() === today.getDate()
            ) {
                return true;
            }

            return false;

        },
        // 今日より後かどうか
        isGraterThanToday(date) {
            let target_day = new Date(date);
            var year = target_day.getFullYear();
            var month = target_day.getMonth() + 1;
            var day = target_day.getDate();

            let today = new Date();
            var today_year = today.getFullYear();
            var today_month = today.getMonth() + 1;
            var today_day = today.getDate();

            if (year === today_year) {
                if (month === today_month) {
                    return day >= today_day;
                }
                else {
                    return month >= today_month;
                }
            } else {
                return year >= today_year;
            }
        },
        // 前の月へ
        subMonth() {
            let date = new Date(this.condition.date);
            date.setMonth(date.getMonth() - 1);
            let y = date.getFullYear();
            let m = ("00" + (date.getMonth()+1)).slice(-2);
            let d = ("00" + date.getDate()).slice(-2);
            let result = y + "-" + m + "-" + d;

            this.condition.date = result;
            this.fetchCalendars();
        },
        // 次の月へ
        addMonth() {
            let date = new Date(this.condition.date);
            date.setMonth(date.getMonth() + 1);
            let y = date.getFullYear();
            let m = ("00" + (date.getMonth()+1)).slice(-2);
            let d = ("00" + date.getDate()).slice(-2);
            let result = y + "-" + m + "-" + d;

            this.condition.date = result;
            this.fetchCalendars();
        },
        // 今月へ
        todayMonth() {
            let date = new Date();
            let y = date.getFullYear();
            let m = ("00" + (date.getMonth()+1)).slice(-2);
            let d = ("00" + date.getDate()).slice(-2);
            let result = y + "-" + m + "-" + d;

            this.condition.date = result;
            this.fetchCalendars();
        },
        // カレンダーの日付の表示色
        generateHeaderDayColor(calendar, index) {
            // 0:月 1:火 2:水 3:木 4:金 5:土 6:日
            if (index === 6 || calendar.is_holiday === IsHoliday.YES) {
                return 'text-danger';
            }

            if (index === 5) {
                return 'text-info';
            }

            return 'text-dark';
        },
        // 今日の日付の表示色
        generateHeaderTodayColor(calendar, index) {
            // 0:月 1:火 2:水 3:木 4:金 5:土 6:日
            if (this.isToday(calendar)) {
                if (index === 5) {
                    return 'today-color-saturday';
                }

                if (index === 6 || calendar.is_holiday === IsHoliday.YES) {
                    return 'today-color-sunday';
                }

                return 'today-color-weekday';
            }
            return '';
        },
        // カレンダーの表示色
        displayHeaderColor(calendar) {
            // カメラマン不足
            if (calendar.shortage_status === ShortageStatus.DANGER) {
                return 'alert-shortage-danger';
            }
            // カメラマン不足気味
            if (calendar.shortage_status === ShortageStatus.WARNING) {
                return 'alert-shortage-warning';
            }

            return 'bg-light'
        },
        isVisibleDoubleBookingAlert(calendar) {
            // ダブルブッキング
            let alert_double_booking = this.double_booking_alerts.findIndex(alert => {
                let calendar_date = new Date(calendar.date);
                let alert_date = new Date(alert.alert_date);

                return (
                    calendar_date.getFullYear() === alert_date.getFullYear() &&
                    calendar_date.getMonth() === alert_date.getMonth() &&
                    calendar_date.getDate() === alert_date.getDate()
                );
            })
            if (alert_double_booking > - 1) {
                return true;
            }
        },
        isVisibleEquipmentAlert(calendar) {
            // 備品不足
            let alert_equipment = this.equipment_alerts.findIndex(alert => {
                let calendar_date = new Date(calendar.date);
                let alert_date = new Date(alert.alert_date);

                return (
                    calendar_date.getFullYear() === alert_date.getFullYear() &&
                    calendar_date.getMonth() === alert_date.getMonth() &&
                    calendar_date.getDate() === alert_date.getDate()
                );
            })
            if (alert_equipment > - 1) {
                return true;
            }
        },
        // スケジュールの表示色
        displayScheduleColor(schedule) {
            if (schedule.has_first_confirm_alert === IsAlert.YES) {
                return 'alert-confirm-first';
            }
            if (schedule.has_last_confirm_alert === IsAlert.YES) {
                return 'alert-confirm-last';
            }
            if (schedule.has_spare_schedule_alert === IsAlert.YES) {
                return 'alert-spare-schedule';
            }
            if (schedule.has_unselected_photographer_schedule_alert === IsAlert.YES) {
                return 'alert-unselected';
            }
            if (schedule.has_no_contact_photographer_schedule_alert === IsAlert.YES) {
                return 'alert-contact';
            }
            if (schedule.has_status_list_alert === IsAlert.YES) {
                return 'alert-status';
            }

            return 'bg-light'
        },
        // カレンダー他何件か表示
        displayAnotherSchedulesNumber(calendar, week) {
            let another_schedule_number = calendar.schedules.length - this.displayableNumber(week);
            if (another_schedule_number < 0) {
                return 0;
            }
            return another_schedule_number;
        },

        /**
         * Fetch
         */
        // 全ての学校取得
        fetchAllSchools() {
            this.loading++;

            this.$http.get('/schools/all')
            .then(response => {
                for (let row of response.data) {
                    this.options_school.push({key: row.school_id, label: row.school_name})
                }
            })
            .finally(() => {
                this.loading--;
            });
        },
        // 全ての社員取得
        fetchAllEmployees() {
            this.loading++;

            this.$http.get('/employees/all')
            .then(response => {
                for (let row of response.data) {
                    let employee = new Employee(row);
                    this.employees.push(employee);
                    this.options_employee.push({key: employee.photographer_id, label: employee.photographer_name});
                    // カメラマンの社員のみ
                    if (employee.is_photographer === IsPhotographer.PHOTOGRAPHER) {
                        this.options_search_photographer.push({key: employee.photographer_id, label: employee.photographer_name});
                        this.options_employee_photographer.push({key: employee.photographer_id, label: employee.photographer_name});
                    }
                }
            })
            .finally(() => {
                this.loading--;
            });
        },
        // 全ての応援カメラマン取得
        fetchAllPartners() {
            this.loading++;

            this.$http.get('/partners/all')
            .then(response => {
                for (let row of response.data) {
                    this.options_search_photographer.push({key: row.photographer_id, label: row.photographer_name});
                }
            })
            .finally(() => {
                this.loading--;
            });
        },
        // 店舗を取得してoptions用に整形
        fetchAllStudiosForOptions() {
            this.options_studio_loading++;

            this.$http.get('/studios')
            .then(response => {
                for (let row of response.data) {
                    this.options_studio.push({key: row.studio_id, label: row.studio_name});
                }
            })
            .finally(() => {
                this.options_studio_loading--;
            });
        },
        // 全ての社用車取得
        fetchCarList() {
            this.loading++;

            this.$http.get(`/cars`)
            .then(response => {
                for (let row of response.data) {
                    this.cars.push(new Car(row));
                }
            })
            .finally(() => {
                this.loading--;
            });
        },
        // 全ての備品取得
        FetchEquipmentList() {
            this.loading++;

            this.$http.get('/equipments')
            .then(response => {
                for (let row of response.data) {
                    this.equipments.push(new Equipment(row));
                }
            })
            .finally(() => {
                this.loading--;
            });
        },
        // カレンダー全件取得
        fetchCalendars(is_auto_fetching = false) {
            // 自動更新のときはローディングを出さない
            if (!is_auto_fetching) {
                this.calendar_loading++;
            }

            // アラートも再取得(photographer_idで検索ができるため)
            this.fetchAllAlerts();

            this.$http.get('/calendars', {
                params: this.condition
            })
            .then(response => {
                this.calendars.splice(0);

                for (let one_week of response.data) {
                    let week = [];
                    for (let calendar of one_week) {
                        week.push(new Calendar(calendar));
                    }
                    this.calendars.push(week);
                }
            })
            .finally(() => {
                if (!is_auto_fetching) {
                    this.calendar_loading--;
                }
            });
        },
        // カレンダー一件取得
        fetchCalendar(date) {
            // オフキャンバスが開いていなければreturn
            if (!this.schedule_offcanvas_show) {
                return;
            }

            this.offcanvas_loading++;
            this.offcanvas_schedules.splice(0);

            this.$http.get(`/calendars/${date}`,{
                params: this.condition
            })
            .then(response => {
                for (let schedule of response.data.schedules) {
                    this.offcanvas_schedules.push(new Schedule(schedule));
                }
            })
            .finally(() => {
                this.offcanvas_loading--;
            });
        },
        // 前の日へ
        subDay() {
            let date = new Date(this.schedule_offcanvas_date);
            date.setDate(date.getDate()-1);
            let y = date.getFullYear();
            let m = ("00" + (date.getMonth()+1)).slice(-2);
            let d = ("00" + date.getDate()).slice(-2);
            let result = y + "-" + m + "-" + d;

            this.schedule_offcanvas_date = result;
            this.fetchCalendar(this.schedule_offcanvas_date);
        },
        // 次の日へ
        addDay() {
            let date = new Date(this.schedule_offcanvas_date);
            date.setDate(date.getDate()+1);
            let y = date.getFullYear();
            let m = ("00" + (date.getMonth()+1)).slice(-2);
            let d = ("00" + date.getDate()).slice(-2);
            let result = y + "-" + m + "-" + d;

            this.schedule_offcanvas_date = result;
            this.fetchCalendar(this.schedule_offcanvas_date);
        },
        // ランクが「プレミアム」「準レギュラー」の応援カメラマンを取得
        fetchInvitationPartners() {
            this.loading++;

            this.$http.get('/invitations/partners')
            .then(response => {
                for (let row of response.data) {
                    this.invitation_partners.push(new Partner(row));
                }
            })
            .finally(() => {
                this.loading--;
            });
        },
        // ランクが「育成」以下の応援カメラマンを取得
        fetchOtherPartners() {
            this.loading++;

            this.$http.get('/invitations/partners/others')
            .then(response => {
                for (let row of response.data) {
                    this.other_partners.push(new Partner(row));
                    this.options_other_partner.push({key: row.photographer_id, label: row.photographer_name});
                }
            })
            .finally(() => {
                this.loading--;
            });
        },
        // カメラマン不足アラート取得
        fetchDangerShortageAlerts() {
            this.danger_shortage_alert_loading++

            this.danger_shortage_alerts.splice(0);

            this.$http.get('/alerts/shortage/danger')
            .then(response => {
                for (let row of response.data) {
                    this.danger_shortage_alerts.push(new Calendar(row));
                }
            })
            .finally(() => {
                this.danger_shortage_alert_loading--;
            });
        },
        // カメラマン不足気味アラート取得
        fetchWarningShortageAlerts() {
            this.warning_shortage_alert_loading++;

            this.warning_shortage_alerts.splice(0);

            this.$http.get('/alerts/shortage/warning')
            .then(response => {
                for (let row of response.data) {
                    this.warning_shortage_alerts.push(new Calendar(row));
                }
            })
            .finally(() => {
                this.warning_shortage_alert_loading--;
            });
        },
        // ダブルブッキングアラート取得
        fetchDoubleBookingAlerts() {
            this.double_booking_alert_loading++;

            this.double_booking_alerts.splice(0);

            // 今日以前のアラートは表示する数字に含めないので、別に保存する必要がある
            this.double_booking_alert_counts.splice(0);

            this.$http.get('/alerts/double-bookings')
            .then(response => {
                for (let row of response.data) {
                    let alert_double_booking = new AlertDoubleBooking(row);
                    this.double_booking_alerts.push(alert_double_booking);

                    // 今日以降のアラートだけ数字表示用に保存
                    if (this.isGraterThanToday(alert_double_booking.alert_date)) {
                        this.double_booking_alert_counts.push(alert_double_booking);
                    }
                }
            })
            .finally(() => {
                this.double_booking_alert_loading--;
            });
        },
        // 備品不足アラート取得
        fetchEquipmentAlerts() {
            this.equipment_alert_loading++;

            this.equipment_alerts.splice(0);

            // 今日以前のアラートは表示する数字に含めないので、別に保存する必要がある
            this.equipment_alert_counts.splice(0);

            this.$http.get('/alerts/equipments')
            .then(response => {
                for (let row of response.data) {
                    let alert_equipment = new AlertEquipment(row);
                    this.equipment_alerts.push(alert_equipment);

                    // 今日以降のアラートだけ数字表示用に保存
                    if (this.isGraterThanToday(alert_equipment.alert_date)) {
                        this.equipment_alert_counts.push(alert_equipment);
                    }
                }
            })
            .finally(() => {
                this.equipment_alert_loading--;
            });
        },
        // 未配置アラート取得
        fetchUnassignedAlerts() {
            this.unassigned_alert_loading++;

            this.unassigned_alerts.splice(0);

            this.$http.get('/alerts/unassigned')
            .then(response => {
                for (let row of response.data) {
                    let alert_unassigned = new Invitation(row);
                    this.unassigned_alerts.push(alert_unassigned);
                }
            })
            .finally(() => {
                this.unassigned_alert_loading--;
            });
        },
        // 日程未定予定アラート取得
        fetchAlertUndecidedSchedules() {
            this.undecided_schedules_alert_loading++;

            this.alert_undecided_schedules.splice(0);

            this.$http.get('/alerts/undecided-schedules',{
                params: {
                    photographer_id: this.condition.photographer_id,
                }
            })
            .then(response => {
                for (let row of response.data) {
                    this.alert_undecided_schedules.push(new UndecidedSchedule(row));
                }
            })
            .finally(() => {
                this.undecided_schedules_alert_loading--;
            });
        },
        // 日程確認未実施アラート取得
        fetchFirstConfirmAlerts() {
            this.first_confirm_alert_loading++;

            this.first_confirm_alerts.splice(0);

            this.$http.get('/alerts/confirm/first',{
                params: {
                    photographer_id: this.condition.photographer_id,
                }
            })
            .then(response => {
                for (let row of response.data) {
                    this.first_confirm_alerts.push(new Schedule(row));
                }
            })
            .finally(() => {
                this.first_confirm_alert_loading--;
            });
        },
        // 日程最終確認未実施アラート取得
        fetchLastConfirmAlerts() {
            this.last_confirm_alert_loading++;

            this.last_confirm_alerts.splice(0);

            this.$http.get('/alerts/confirm/last',{
                params: {
                    photographer_id: this.condition.photographer_id,
                }
            })
            .then(response => {
                for (let row of response.data) {
                    this.last_confirm_alerts.push(new Schedule(row));
                }
            })
            .finally(() => {
                this.last_confirm_alert_loading--;
            });
        },
        // 予備日未定アラート取得
        fetchSpareScheduleAlerts() {
            this.spare_schedule_alert_loading++;

            this.spare_schedule_alerts.splice(0);

            this.$http.get('/alerts/spare-schedules',{
                params: {
                    photographer_id: this.condition.photographer_id,
                }
            })
            .then(response => {
                for (let row of response.data) {
                    this.spare_schedule_alerts.push(new Schedule(row));
                }
            })
            .finally(() => {
                this.spare_schedule_alert_loading--;
            });
        },
        // カメラマン未選択アラート取得
        fetchUnselectedAlerts() {
            this.unselected_alert_loading++;

            this.unselected_alerts.splice(0);

            this.$http.get('/alerts/photographers/unselected',{
                params: {
                    photographer_id: this.condition.photographer_id,
                }
            })
            .then(response => {
                for (let row of response.data) {
                    this.unselected_alerts.push(new Schedule(row));
                }
            })
            .finally(() => {
                this.unselected_alert_loading--;
            });
        },
        // カメラマン未連絡アラート取得
        fetchContactAlerts() {
            this.contact_alert_loading++;

            this.contact_alerts.splice(0);

            this.$http.get('/alerts/photographers/no-contact',{
                params: {
                    photographer_id: this.condition.photographer_id,
                }
            })
            .then(response => {
                for (let row of response.data) {
                    this.contact_alerts.push(new Schedule(row));
                }
            })
            .finally(() => {
                this.contact_alert_loading--;
            });
        },
        // ステータス未更新アラート取得
        fetchStatusAlerts() {
            this.status_alert_loading++;

            this.status_alerts.splice(0);

            this.$http.get('/alerts/status',{
                params: {
                    photographer_id: this.condition.photographer_id,
                }
            })
            .then(response => {
                for (let row of response.data) {
                    this.status_alerts.push(new Schedule(row));
                }
            })
            .finally(() => {
                this.status_alert_loading--;
            });
        },
        // 全てのアラートを取得する
        fetchAllAlerts() {
            this.fetchDangerShortageAlerts();
            this.fetchWarningShortageAlerts();
            this.fetchDoubleBookingAlerts();
            this.fetchEquipmentAlerts();
            this.fetchUnassignedAlerts();
            this.fetchAlertUndecidedSchedules();
            this.fetchFirstConfirmAlerts();
            this.fetchLastConfirmAlerts();
            this.fetchSpareScheduleAlerts();
            this.fetchUnselectedAlerts();
            this.fetchContactAlerts();
            this.fetchStatusAlerts();
        },
        // ランクが「プレミアム」「準レギュラー」の社員と依頼トランのデータをマージする
        pickInvitations(date, invitations) {
            for (let invitation_partner of this.invitation_partners) {
                let index = invitations.findIndex(invitation => parseInt(invitation_partner.photographer_id, 10) === parseInt(invitation.photographer.photographer_id, 10));

                if (index === -1) {
                    let invitation = new Invitation();
                    invitation.photographer = invitation_partner;
                    invitation.invitation_date = date;
                    invitations.push(invitation);
                }
            }
        },
        // 社用車の名前を表示する
        displayCarShortenedName(car_ids) {
            let display_car_names;
            if (car_ids.length) {
                let filtered_cars = this.cars.filter(car => car_ids.includes(car.car_id));
                let car_name_array = filtered_cars.map((car) => {
                    return `[${car.shortened_name}]`;
                });

                // 表示する社用車の名前は一人まで
                let car_names = car_name_array.splice(0,1);

                display_car_names = car_names.join('');
                if (car_name_array.length) {
                    display_car_names = display_car_names + ` 他${car_name_array.length}`;
                }
            }

            return display_car_names;
        },
        // スケジュールの時刻を表示する
        displayScheduleTime(target_schedule) {
            // 時間未定
            if (target_schedule.is_unfixed === IsUnfixed.UNFIXED) {
                // AM
                if (target_schedule.unfixed_type === UnfixedType.AM) {
                    return '時間未定 AM';
                }
                // PM
                if (target_schedule.unfixed_type === UnfixedType.PM) {
                    return '時間未定 PM';
                }
                // 放課後
                if (target_schedule.unfixed_type === UnfixedType.AFTER_SCHOOL) {
                    return '時間未定 放課後';
                }
                // 不明
                if (target_schedule.unfixed_type === UnfixedType.UNCERTAIN) {
                    return '時間未定 不明';
                }
            }
            return target_schedule.schedule_time;
        },
        // 1日のスケジュールの表示量(今日を含む週は１５件、それ以外の週は１０件)
        displayableNumber(week) {
            for (let calendar of week) {
                if (this.isToday(calendar)) {
                    return 15;
                }
            }

            return 10;
        },
        // 応援カメラマン依頼トランの数を数える
        countInvitation(invitations) {
            return invitations.filter(invitation => invitation.invitation_status === InvitationStatus.DONE).length;
        },
        // オフキャンバスオープン
        openOffcanvasSchedules(calendar) {
            this.schedule_offcanvas_show = true;
            this.schedule_offcanvas_date = calendar.date_for_input;
            this.fetchCalendar(this.schedule_offcanvas_date);
        },

        /**
         * スケジュールモーダル
         */
        // スケジュール登録モーダル
        openCreateScheduleModal(date) {
            this.target_schedule = new Schedule();
            this.target_schedule.schedule_date = date;
            this.schedule_modal_title = 'スケジュール 新規登録'
            this.schedule_modal_show = true;
        },
        // スケジュール詳細モーダル
        async openUpdateScheduleModal(target_schedule) {
            let schedule_id = target_schedule.schedule_id;

            if (
                target_schedule.first_schedule &&
                target_schedule.has_several_days === HasSeveralDays.YES &&
                'schedule_id' in target_schedule.first_schedule
            ) {
                schedule_id = target_schedule.first_schedule.schedule_id;
            }

            await this.fetchSchedule(schedule_id);
            await this.fetchRemainingEquipmentQuantity(target_schedule);
            this.schedule_modal_show = true;
            this.schedule_modal_title = 'スケジュール 詳細';
        },
        // モーダル用にスケジュールを一件取得
        fetchSchedule(schedule_id) {
            return new Promise(resolve => {
                this.target_schedule = new Schedule();

                this.startScreenLoading();

                this.$http.get(`/schedules/${schedule_id}`)
                .then(response => {
                    this.target_schedule = new Schedule(response.data);
                })
                .finally(() => {
                    this.endScreenLoading();
                    resolve(true);
                });
            });
        },
        // その日の備品の在庫を再表示
        fetchRemainingEquipmentQuantity(schedule) {
            return new Promise(resolve => {
                this.startScreenLoading();

                this.$http.get('/remaining-equipment-quantity', {
                    params: {schedule_date: schedule.schedule_date_for_input}
                })
                .then(response => {
                    this.remaining_equipments = response.data;
                })
                .finally(() => {
                    this.endScreenLoading();
                    resolve(true);
                });
            });
        },
        fetchParentSchedule(spare_schedule_id) {
            return new Promise(resolve => {
                this.parent_schedule = new Schedule();

                this.startScreenLoading();

                this.$http.get(`/schedules/${spare_schedule_id}`)
                .then(response => {
                    this.parent_schedule = new Schedule(response.data.parent_schedule);
                })
                .finally(() => {
                    this.endScreenLoading();
                    resolve(true);
                });
            });
        },
        // ターゲットのスケジュールのインデックスを取得
        setScheduleIndexes(week_index = null, calendar_index = null, schedule_index = null) {
            this.week_index = week_index;
            this.calendar_index = calendar_index;
            this.schedule_index = schedule_index;
        },
        // ターゲットのスケジュールを削除する
        deleteTargetSchedule() {
            this.calendars[this.week_index][this.calendar_index].schedules.splice(this.schedule_index, 1);
            this.setScheduleIndexes()
        },

        // 休暇モーダルオープン
        openAbsenceModal(absences, schedules, date) {
            this.absence_modal_absences = absences;
            this.absence_modal_schedules = schedules;
            this.absence_modal_date = date;
            this.absence_modal_show = true;
            this.clearAutoFetchCalendars();

        },
        // 応援カメラマン依頼モーダルオープン
        openInvitationModal(invitations, schedules, date) {
            this.invitation_modal_invitations = invitations;
            this.invitation_modal_schedules = schedules;
            this.invitation_modal_date = date;
            this.invitation_modal_show = true;
            this.clearAutoFetchCalendars();
        },
        // カメラマン不足モーダルオープン
        openDangerShortageAlertModal() {
            this.danger_shortage_alert_modal = true;
            this.clearAutoFetchCalendars();
        },
        // カメラマン不足気味モーダルオープン
        openWarningShortageAlertModal() {
            this.warning_shortage_alert_modal = true;
            this.clearAutoFetchCalendars();
        },
        jumpToShortageAlertMonth(date) {
            this.condition.date = date;
            this.fetchCalendars();
        },
        // ダブルブッキングモーダルオープン
        openDoubleBookingAlertModal() {
            this.double_booking_alert_modal = true;
            this.clearAutoFetchCalendars();
        },
        // 備品不足モーダルオープン
        openEquipmentAlertModal() {
            this.equipment_alert_modal = true;
            this.clearAutoFetchCalendars();
        },
        // 未配置モーダルオープン
        openUnassignedAlertModal() {
            this.unassigned_alert_modal = true;
            this.clearAutoFetchCalendars();
        },
        // 日程確認未実施モーダルオープン
        openFirstConfirmAlertModal() {
            this.first_confirm_alert_modal = true;
            this.clearAutoFetchCalendars();
        },
        // 最終日程確認モーダルオープン
        openLastConfirmAlertModal() {
            this.last_confirm_alert_modal = true;
            this.clearAutoFetchCalendars();
        },
        // 予備日未定モーダルオープン
        openSpareScheduleAlertModal() {
            this.spare_schedule_alert_modal = true;
            this.clearAutoFetchCalendars();
        },
        // カメラマン未選択モーダルオープン
        openUnselectedAlertModal() {
            this.unselected_alert_modal = true;
            this.clearAutoFetchCalendars();
        },
        // カメラマン未連絡モーダルオープン
        openContactAlertModal() {
            this.contact_alert_modal = true;
            this.clearAutoFetchCalendars();
        },
        // ステータス未設定モーダルオープン
        openStatusAlertModal() {
            this.status_alert_modal = true;
            this.clearAutoFetchCalendars();
        },
        // 本予定モーダルオープン
        async openParentScheduleModal(spare_schedule_id) {
            await this.fetchParentSchedule(spare_schedule_id);
            this.is_readonly = true;
            this.parent_schedule_modal_show = true;
            this.parent_schedule_modal_title = '本予定詳細';
            this.parent_schedule_modal_title_notice = '※編集不可';
        },

        /**
         * カレンダー自動取得(5分)
         */
        setAutoFetchCalendars() {
            let set_time_out = 1000 * 60 * 5;
            this.auto_fetch_calendars = setTimeout(() => {
                if (this.isFetchingCalendars) {
                    this.fetchCalendars(true);
                }
                this.setAutoFetchCalendars();
            }, set_time_out);
        },
        // 自動停止
        clearAutoFetchCalendars() {
            clearTimeout(this.auto_fetch_calendars);
        },

    }
}
</script>

<style scoped>
    .schedule-name {
        overflow: hidden;
        white-space: nowrap;
        width: 8rem;
        text-overflow: ellipsis;
    }
    .schedule-status {
        position: relative;
    }
    .schedule-status-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.15;
        font-size: 2.8rem;
    }
    .today-color {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 1.8rem;
        height: 1.8rem;
        margin-top: 0.2rem;
        border-radius: 50%;
    }
    .today-color-weekday {
        background-color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
        color: #fff;
    }
    .today-color-saturday {
        background-color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
        color: #fff;
    }
    .today-color-sunday {
        background-color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
        color: #fff;
    }
    .is-visible-alert {
        margin: 0;
        width: 1.6rem;
        height: 1.6rem;
        font-size: 1.2rem;
        color: #000;
    }
    .display-position-equipment-alert {
        left: 20%;
    }
</style>
